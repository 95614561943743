import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';

export const Banner = ({name, sub_items, id, icon, image, ...rest}) => {
  const history = useHistory();
  const {t} = useTranslation();
  
  return (
    <div
      className="banner home"
      style={{cursor: 'pointer'}}
      onClick={() => history.push(`/categories/${rest.url_repr}/${id}`)}>
      <div className="banner-img" style={{zIndex: 1}}>
        <img src={image[0]} alt="banner" style={{objectFit:'cover'}} />
      </div>
      <div className="banner-left" style={{zIndex: 2}}>
        <div className="banner-icon">
          <img src={icon && icon.length > 0 ? icon[0] : null} />
        </div>
      </div>
      <div className="banner-right" style={{zIndex: 2}}>
        <Link
          to={`/categories/${rest.url_repr}/${id}`}
          className="banner-title"
          style={{cursor: 'pointer'}}>
          {name}
          <div>
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.45082 1L12.9974 6.23494L8.45082 11M13 6.18882H0.999999H13Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Link>
        <div className="banner-description" style={{zIndex: 2}}>
          {rest.count_sub_items} {t('common:categories_count')},{' '}
          {rest.count_products} {t('common:products_count')}
        </div>
      </div>
    </div>
  );
};
