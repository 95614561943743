import {userConstants} from '../_constants';
import {alertActions} from './';
import {catalogService} from '../_services/catalog.service';
import {errorMessage} from '../_constants/errorMessage.constants';
import {store} from "../_helpers";

export const catalogActions = {
  getCatalogTree,
  getCatalogByGroup,
  getPageProductGroup,
};

function getCatalogTree(blockType) {
  return dispatch => {
    dispatch(request());

    catalogService.getCatalogTree(blockType).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_CATALOG_TREE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_CATALOG_TREE_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_CATALOG_TREE_FAILURE, error};
  }
}

function searchInProgress(data) {
    return {type: userConstants.SET_SEARCH_CATALOG_IN_PROGRESS, data};
}
function searchDone() {
    // const state = store.getState();
    // const {searchParams} = state;
    // searchParams['searchStat']='final'
    return {type: userConstants.SEARCH_CATALOG_DONE};
}
function resetSearchResults() {
    return {type: userConstants.RESET_SEARCH_RESULTS};
}
function setSearchCatalogData(data) {
        return {type: userConstants.SET_SEARCH_CATALOG_DATA, data};
    }
function getCatalogByGroup(setData, id, oriPath, brands=[]) {
    const state = store.getState();
    const {searchParams} = state;
  return dispatch => {
    dispatch(resetSearchResults());
    dispatch(searchInProgress(oriPath));
    if(setData === 'setData') dispatch(setSearchCatalogData({id: id, brands: brands}));
    dispatch(request());
    catalogService.getCatalogByGroup(id,brands).then(
      data => {
        dispatch(success(data, id));
        dispatch(searchDone());
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(searchDone());
        //dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
        searchParams['searchStat']='final'
    );
  };

  function request() {
    return {type: userConstants.GET_CATALOG_BY_GROUP_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_CATALOG_BY_GROUP_SUCCESS, data, id};
  }

  function failure(error) {
    return {type: userConstants.GET_CATALOG_BY_GROUP_FAILURE, error};
  }
}

function getPageProductGroup(path) {
  return dispatch => {
    dispatch(request());

    catalogService.getPageProductGroup(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_PAGE_PRODUCT_GROUP_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_PAGE_PRODUCT_GROUP_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_PAGE_PRODUCT_GROUP_FAILURE, error};
  }
}