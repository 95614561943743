import React, { useEffect, useState } from 'react';
import axInst from '../../../../_helpers/axios-instance';
import { GoogeMap } from '../GoogleMap';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  maps: {
    width: "100%",
    height: "400px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
    [theme.breakpoints.down(991)]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

export const Contacts = () => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  useEffect(() => {
    axInst
      .post('/api/static-page/', { slug: 'contacts' })
      .then(res => setData(res.data));
  }, []);

  if (!data) return <></>;
  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <div className="products-block contact mt-5">
      <div className="wrapper-inner">
        <div className="products-block__header">
          <h2 className="title mb-4">{data.name}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 col-12">
            <div id="map">
              <div className={classes.maps}>
                <iframe style={{
                  width: '100%',
                  height: '100%',
                  border: 0,
                  outline: 0
                }}
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0pXWFUwYNWv8QExnAYI8kX0L_mUcel6c
    &q=Дані-партс,+ТОВ+Unnamed+Road+Корнин,+Рівненська+область+35304"></iframe>
                <iframe
                  style={{
                    width: '100%',
                    height: "100%",
                    border: 0,
                    outline: 0
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d20600.794566429897!2d23.8692708!3d49.8030004!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDQ4JzQ3LjciTiAyM8KwNTInMzEuMyJF!5e0!3m2!1suk!2sua!4v1721311833765!5m2!1suk!2sua"></iframe>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-12 mt-lg-0 mt-4  contact-right"
            dangerouslySetInnerHTML={createMarkup(data.html)}></div>
        </div>
      </div>
    </div>
  );
};
