import React, { useEffect, useState } from 'react'
// import material ui
import { makeStyles } from '@material-ui/styles';
import {
    Dialog,
    Box,
    Typography,
    IconButton
} from '@material-ui/core';
import clsx from 'clsx';
// import language
import { useTranslation } from 'react-i18next';
// import redux
import { connect } from 'react-redux';
import { wishlistActions } from '../_actions';
// import fetch
import axInst from '../_helpers/axios-instance';

const useStyles = makeStyles(theme => ({
    divider: {
        margin: '8px 0 24px 0',
    },
    stMainHeader: {
        color: '#008CB4',
        fontSize: 18,
        fontWeight: 'bold',
    },
    stMessage: {
        color: '#606060',
        fontSize: 14,
    },
    signOutButton: {
        margin: '16px 0 4px 0',
        padding: '4px 8px',
    },
    modalCloseBtn: {
        position: 'absolute',
        top: '15px',
        right: '15px',
        padding: 5,
        '& div': {
            width: 22,
            height: 22,
        },
    },
}));

const WishlistMessage = (props) => {
    const { wishlistFlagMessage, getWishlistFlagMessage, customer_id } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const { loading, data } = wishlistFlagMessage;
    const [currentMessageData, setCurrentMessageData] = useState([]);

    const handleCloseMessage = (currentMessageData) => {
        currentMessageData.map((item) => {
            axInst.post("/api/seen-wish-list-message/", JSON.stringify({
                customer_id: customer_id,
                product_id: item.wish_list_product__product__id,
            }))
        });
        setCurrentMessageData([]);
    };

    useEffect(() => {
        getWishlistFlagMessage();
    }, []);

    useEffect(() => {
        if (customer_id !== 4890 && loading === false && data.length !== 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].is_seen_message === false && data[i].wish_list_product__show_message === true) {
                    setCurrentMessageData(prev => [...prev, data[i]]);
                }
            }
        }
    }, [wishlistFlagMessage]);

    return (
        <Dialog
            onClose={handleCloseMessage}
            maxWidth="xs"
            fullWidth={true}
            BackdropProps={{ style: { backgroundColor: 'rgba(31, 31, 31, 0.6)' } }}
            open={currentMessageData.length !== 0 ? true : false}>
            <Box className="modal-info" id="modal-info">
                <Box className="modal-content">
                    <IconButton
                        className={clsx('button-close', classes.modalCloseBtn)}
                        style={{ float: 'right', marginTop: -5 }}
                        onClick={handleCloseMessage}
                        size={'small'}>
                        <Box>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
                                    stroke="#414D5F"
                                    strokeOpacity="0.5"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </Box>
                    </IconButton>
                    <Box className="modal-body">
                        <Typography className="modal-title mb-4">
                            {t('common:warn_message2_countdown_title')}
                        </Typography>
                        <Typography className="modal-description mb-4">
                            {currentMessageData.length === 1 ? t('common:wishlist_message1') : t('common:wishlist_message2')}
                            <span style={{ fontWeight: 600 }}>{currentMessageData.map((item, index) => index !== currentMessageData.length - 1 ? ` ${item.wish_list_product__product__article},` : ` ${item.wish_list_product__product__article}`)}</span>
                            {' '}{currentMessageData.length === 1 ? t('common:wishlist_message3') : t('common:wishlist_message4')}
                        </Typography>
                        <button onClick={() => {
                            handleCloseMessage(currentMessageData)
                        }} className="btn btn-big">
                            {t('common:warn_message2_countdown_confirm_btn')}
                        </button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

function mapState(state) {
    const {
        wishlistFlagMessage,
        userSelectedData,
    } = state;
    const { customer_id } = userSelectedData;
    return {
        wishlistFlagMessage,
        customer_id,
    };
}

const actionCreators = {
    getWishlistFlagMessage:
        wishlistActions.getWishlistFlagMessage,
};

export default connect(mapState, actionCreators)(WishlistMessage);