import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {TopBarMenu} from '../TopBarMenu/TopBarMenu';
import {makeStyles} from '@material-ui/styles';
import SelectLanguage from '../../../../../../_components/SelectLanguage';
import {useTranslation} from 'react-i18next';
import {Category} from './components/Category';
import {catalogActions} from '../../../../../../_actions';
import {connect} from 'react-redux';
import CategoryAccordion from './components/CategoryAccordion';
import {Link} from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function FullScreenDialog({setOpenMenuBar, openMenuBar, catalogTree}) {
  const {t} = useTranslation();

  const [showCategoryList, setShowCategoryList] = useState(false);

  const handleClose = () => setOpenMenuBar(false);
  const handleCloseCategoryList = () => setShowCategoryList(!showCategoryList);

  return (
    <div>
      <Dialog
        // maxWidth={1000}
        // style={{maxWidth: '50%'}}
        fullScreen
        open={openMenuBar}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="header-mobile">
        <div className="close-icon" onClick={handleClose}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              width="20.8866"
              height="1.74055"
              rx="0.870274"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 16 14.7695)"
              fill="white"
            />
            <rect
              y="14.7695"
              width="20.8866"
              height="1.74055"
              rx="0.870274"
              transform="rotate(-45 0 14.7695)"
              fill="white"
            />
          </svg>
        </div>
        <div className="header-mobile__inner">
          <div className="wrapper-inner">
            <TopBarMenu />
            <div className="line"></div>
            <div className="header-mobile__flex">
              <Link to={'/wishlist'} className="header-mobile__like">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.06353 5.12549C0.67387 3.01135 2.11393 1.18 4.35874 1.00703C6.3353 0.856016 7.31511 3.18432 7.51277 3.62912C7.51496 3.63658 7.51959 3.64314 7.52596 3.64781C7.53232 3.65248 7.54008 3.65501 7.54806 3.65501C7.55604 3.65501 7.5638 3.65248 7.57017 3.64781C7.57654 3.64314 7.58117 3.63658 7.58336 3.62912C8.50105 1.18275 10.1727 1.07841 10.76 1.02076C12.3412 0.856016 14.4618 2.04488 14.0354 4.81524C13.4255 8.65914 7.50712 13 7.50712 13C7.50712 13 1.83439 9.25769 1.06353 5.12549Z"
                    stroke="#1F1F1F"
                    strokWidth="1.6"
                  />
                </svg>
                {t('common:favorites')}
              </Link>
              <div className="header__group-item header__group-item__langs">
                <SelectLanguage list />
              </div>
            </div>
            <div className="line mb-4"></div>
            {/* <CategoryAccordion /> */}
            {catalogTree && catalogTree?.menu_block && (
              <Category
                open={showCategoryList}
                onClose={handleCloseCategoryList}
                catalogTree={catalogTree?.menu_block}
              />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapState = state => {
  const catalogTree = state.catalogTree && state.catalogTree.data;
  return {catalogTree};
};

export default connect(mapState, {})(FullScreenDialog);
